import { BrowserRouter, Routes, Route } from "react-router-dom";
import { messengerFlagLoader } from "gx-npm-lib";
import { FeatureFlagProvider } from "gx-npm-ui";
import App from "./app.component";
import { ProposalReviewContextProvider } from "./app.context";

const Root = () => {
  return (
    <FeatureFlagProvider loader={messengerFlagLoader()}>
      <ProposalReviewContextProvider>
        <BrowserRouter>
          <Routes>
            <Route
              path={"/s/evaluation/:initiativeId/product/:initiativeProductId/proposal-review"}
              element={<App />}
            />
          </Routes>
        </BrowserRouter>
      </ProposalReviewContextProvider>
    </FeatureFlagProvider>
  );
};

export default Root;
