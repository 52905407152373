import { useContext, FormEvent, useEffect } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { TextField, TypographyComponent } from "gx-npm-ui";
import styles from "./comments.styles.module.scss";
import { ProposalReviewContext } from "../../../../app.context";
import { Section } from "../../../../app.types";

const Comments = () => {
  const { t } = useTranslation();
  const maxAllowedChars = 400;

  const { comments, hasFormError, selectedSection, setComments, setHasFormError } = useContext(ProposalReviewContext);

  useEffect(() => {
    if (hasFormError) {
      return;
    }
    setHasFormError(comments.length > maxAllowedChars);
  }, [comments, hasFormError, setHasFormError]);

  const handleSetComments = (event: FormEvent<HTMLInputElement>) => {
    setComments(event.currentTarget.value);
  };

  return (
    <div className={styles.root} id={Section.COMMENTS}>
      <div className={styles.title}>
        <TypographyComponent
          boldness="medium"
          color={selectedSection === Section.COMMENTS ? "yam" : "carbon"}
          element="h4"
        >
          {t("Comments")}
        </TypographyComponent>
      </div>
      <div className={classNames(styles.question)}>
        <TypographyComponent color="coal" styling="p2">
          {t("Is there any other information or context you’d like to include? (optional)")}
        </TypographyComponent>
      </div>
      <TextField
        label={t("Comments")}
        maxAllowedChars={maxAllowedChars}
        multilinev2={true}
        onChange={handleSetComments}
        showCharCount={true}
        showVerticalResize={true}
        value={comments}
        showOverflow={true}
      />
    </div>
  );
};

export default Comments;
