import React, { ReactNode, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { postAsyncRequest, putAsyncRequest } from "gx-npm-lib";
import { ProposalReviewContext } from "../../../../app.context";
import styles from "./document-file-upload.styles.module.scss";

const allowedExtensions = ["doc", "docx", "ppt", "pptx", "xls", "xlsx", "pdf", "txt"];
const MAX_FILE_SIZE = 1024 * 1024 * 15;
const MAX_FILES_ALLOWED = 5;

type FileUploadProps = {
  children?: Array<ReactNode> | ReactNode | number | string;
  onLoad?: (loading: boolean) => void;
};

const DocumentFileUpload = React.forwardRef<HTMLInputElement, FileUploadProps>(
  ({ children, onLoad = () => {} }, ref) => {
    const { t } = useTranslation();
    const {
      documents,
      initId,
      initProductId,
      setDocuments,
      setDocumentValidationError,
      setDocumentValidationErrorMessage,
      setHasError,
    } = useContext(ProposalReviewContext);

    const [dragOver, setDragOver] = useState(false);

    const dropRef = useRef<HTMLDivElement>(null);

    const handleFileUpload = useCallback(
      async (fileList: FileList) => {
        if (!fileList) {
          setHasError(true);
          return;
        }

        if ((documents ? documents.length : 0) + fileList.length > MAX_FILES_ALLOWED) {
          setDocumentValidationError(true);
          setDocumentValidationErrorMessage(t("You attempted to add more than 5 files. Please try again.") as string);
          return;
        }

        onLoad(true);
        setDocumentValidationError(false);
        setDocumentValidationErrorMessage("");

        const filePromises: Promise<{ id: string; fileName: string }>[] = [];

        for (let i = 0; i < fileList.length; i++) {
          const fileObj = fileList[i];

          filePromises.push(
            new Promise((resolve, reject) => {
              const fileName = fileObj.name;

              if (fileObj.size > MAX_FILE_SIZE) {
                reject({ fileName });
                return;
              }

              if (!allowedExtensions.some((ext) => fileName.toLowerCase().endsWith(`.${ext}`))) {
                reject({ fileName });
                return;
              }

              (async () => {
                const url = `/api/v2/initiatives/${initId}/products/${initProductId}/proposal-review/upload`;
                const postResponse = await postAsyncRequest(url, { fileName });

                if (postResponse.status !== 201 || !postResponse.data?.data?.signedUrl) {
                  setHasError(true);
                  onLoad(false);
                  return;
                }

                const { id, signedUrl } = postResponse.data.data;

                try {
                  const { type } = fileObj;
                  const reader = new FileReader();

                  reader.onload = async (progressEvent) => {
                    const isValidEvent = typeof progressEvent?.target?.result === "string";
                    const binary = window.atob(isValidEvent ? progressEvent.target.result.split(",")[1] : "");

                    const array = [];

                    for (let idx = 0; idx < binary.length; idx++) {
                      array.push(binary.charCodeAt(idx));
                    }

                    const blobData = new Blob([new Uint8Array(array)], { type });
                    const requestConfig = { headers: { "Content-Type": type } };
                    const response = await putAsyncRequest(signedUrl, blobData, requestConfig);

                    if (response.status === 200) {
                      resolve({ id, fileName });
                    } else {
                      setHasError(true);
                    }
                  };
                  reader.readAsDataURL(fileObj);
                } catch (err) {
                  reject({ fileName });
                }
              })();
            })
          );
        }

        await Promise.allSettled(filePromises).then((results) => {
          onLoad(false);
          results.forEach((file) => {
            if (file.status === "fulfilled") {
              const fileValue = file.value;
              const { id, fileName } = fileValue;
              setDocuments((prevDocuments) => [...prevDocuments, { id, fileName }]);
            } else if (file.status === "rejected") {
              setDocumentValidationError(true);
              setDocumentValidationErrorMessage(t("The file type is not supported or may be too large.") as string);
            }
          });
        });
      },
      [
        documents,
        initId,
        initProductId,
        onLoad,
        setDocuments,
        setDocumentValidationError,
        setDocumentValidationErrorMessage,
        setHasError,
        t,
      ]
    );

    const handleFileChange = (e: React.FormEvent<HTMLInputElement>) => {
      const files = (e.target as HTMLInputElement).files;
      if (files) {
        handleFileUpload(files);
      }
    };

    useEffect(() => {
      let dragDropEnterLeaveCounter = 0;

      const handleDragEnter = () => {
        dragDropEnterLeaveCounter = dragDropEnterLeaveCounter + 1;
        setDragOver(true);
      };
      const handleDragLeave = () => {
        dragDropEnterLeaveCounter = dragDropEnterLeaveCounter - 1;
        if (dragDropEnterLeaveCounter === 0) {
          setDragOver(false);
        }
      };
      const handleDragOver = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
      };

      const handleDrop = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();

        dragDropEnterLeaveCounter = 0;
        setDragOver(false);

        const files = e.dataTransfer?.files;
        if (files) {
          handleFileUpload(files);
        }
      };

      const dragCurrRef = dropRef.current;
      if (dragCurrRef) {
        dragCurrRef.addEventListener("dragenter", handleDragEnter);
        dragCurrRef.addEventListener("dragleave", handleDragLeave);
        dragCurrRef.addEventListener("dragover", handleDragOver);
        dragCurrRef.addEventListener("drop", handleDrop);
      }
      return () => {
        if (dragCurrRef) {
          dragCurrRef.removeEventListener("dragenter", handleDragEnter);
          dragCurrRef.removeEventListener("dragleave", handleDragLeave);
          dragCurrRef.removeEventListener("dragover", handleDragOver);
          dragCurrRef.removeEventListener("drop", handleDrop);
        }
      };
    }, [handleFileUpload]);

    return (
      <div
        aria-label={t("file drop area")}
        className={classNames(styles.root, dragOver && styles.dragOver)}
        ref={dropRef}
      >
        {children}
        <input
          accept=".doc,.docx,.pdf,.ppt,.pptx,.xls,.xlsx,.txt"
          multiple
          onChange={handleFileChange}
          ref={ref}
          style={{ display: "none" }}
          type="file"
        />
      </div>
    );
  }
);

export default DocumentFileUpload;
