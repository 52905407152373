import { useTranslation } from "react-i18next";
import { TypographyComponent } from "gx-npm-ui";
import { Player } from "@lottiefiles/react-lottie-player";
import formLoaderAnimation from "./form-loader.animation.json";
import styles from "./form-loader.styles.module.scss";

const FormLoader = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <Player autoplay={true} className={styles.player} loop={true} src={formLoaderAnimation} />
      <div className={styles.titleWrapper}>
        <TypographyComponent boldness="semi" rootClassName={styles.title} styling="p2">
          {t("We're sending your request to our Gartner Experts who will schedule your proposal review.")}
        </TypographyComponent>
        <TypographyComponent rootClassName={styles.subTitle} styling="p4">
          {t("If you leave this page, your request will not be submitted.")}
        </TypographyComponent>
      </div>
    </div>
  );
};

export default FormLoader;
