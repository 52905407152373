import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import Root from "./root.component";
import "./i18n/i18n.config";
import singleSpaCss from "single-spa-css";

const cssLifecycles = singleSpaCss({
  cssUrls: [],
  webpackExtractedCss: true,
});

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary(_err, _info, _props) {
    return <Fragment />;
  },
});

export const bootstrap = [cssLifecycles.bootstrap, reactLifecycles.bootstrap];
export const mount = [cssLifecycles.mount, reactLifecycles.mount];
export const unmount = [reactLifecycles.unmount, cssLifecycles.unmount];
