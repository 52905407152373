import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { TypographyComponent, XDatePicker } from "gx-npm-ui";
import styles from "./target-dates.styles.module.scss";
import { ProposalReviewContext } from "../../../../app.context";
import { Section } from "../../../../app.types";

const TargetDates = () => {
  const { t } = useTranslation();
  const { contractSignDate, expertSpeakDate, selectedSection, setContractSignDate, setExpertSpeakDate } =
    useContext(ProposalReviewContext);
  const DEFAULT_DATE = "";

  const handleSignatureDateChange = (value: { isValid: () => never; toISOString: () => never }) => {
    const formattedDate = value && value.isValid() ? value.toISOString() : DEFAULT_DATE;
    setContractSignDate(formattedDate);
  };

  const handleExpertDateChange = (value: { isValid: () => never; toISOString: () => never }) => {
    const formattedDate = value && value.isValid() ? value.toISOString() : DEFAULT_DATE;
    setExpertSpeakDate(formattedDate);
  };

  const tomorrowsDate = new Date();
  tomorrowsDate.setDate(tomorrowsDate.getDate() + 1);

  return (
    <div className={styles.root} id={Section.DATES}>
      <TypographyComponent boldness="medium" color={selectedSection === Section.DATES ? "yam" : "carbon"} element="h4">
        {t("Target dates")}
      </TypographyComponent>
      <div className={styles.targetDatesWrapper}>
        <div className={styles.question}>
          <TypographyComponent color="coal" styling="p2">
            {t("When do you plan to sign your contract with the vendor?")}
          </TypographyComponent>
          <div className={styles.required}>
            <TypographyComponent color="statusCherry" rootClassName={styles.asterisk} styling="p1">
              {"*"}
            </TypographyComponent>
            <TypographyComponent color="poisonCherry" rootClassName={styles.requiredText} styling="p3">
              {t("required")}
            </TypographyComponent>
          </div>
        </div>

        <XDatePicker
          minDate={tomorrowsDate}
          onChange={handleSignatureDateChange}
          placeholder={t("Add expected signature date")}
          rootClassName={styles.datePicker}
          popperRootClassName={styles.datePickerPopper}
          showClearButton={true}
          showIcon={true}
          showToolTip={false}
          value={contractSignDate === DEFAULT_DATE ? null : contractSignDate}
        />

        <div className={styles.question}>
          <TypographyComponent color="coal" styling="p2">
            {t("Approximately when would you like to speak with an expert?")}
          </TypographyComponent>
          <div className={styles.required}>
            <TypographyComponent color="statusCherry" rootClassName={styles.asterisk} styling="p1">
              {"*"}
            </TypographyComponent>
            <TypographyComponent color="poisonCherry" rootClassName={styles.requiredText} styling="p3">
              {t("required")}
            </TypographyComponent>
          </div>
        </div>
        <TypographyComponent color="coal" styling="p4">
          {t("Available dates will be sent to you via email for selection based on your request here.")}
        </TypographyComponent>
        <XDatePicker
          minDate={tomorrowsDate}
          onChange={handleExpertDateChange}
          placeholder={t("Select a date")}
          rootClassName={styles.datePicker}
          popperRootClassName={styles.datePickerPopper}
          showClearButton={true}
          showIcon={true}
          showToolTip={false}
          value={expertSpeakDate === DEFAULT_DATE ? null : expertSpeakDate}
        />
      </div>
    </div>
  );
};

export default TargetDates;
