import GartnerLogoTM from "./header-gartner-logo-tm.icon";
import styles from "./header.style.module.scss";

const Header = () => {
  return (
    <section className={styles.root} data-testid="proposal-review-header">
      <GartnerLogoTM />
    </section>
  );
};

export default Header;
