const GartnerLogoTM = () => {
  return (
    <svg width="94" height="23" viewBox="0 0 94 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6479_17783)">
        <path
          d="M93.5162 7.75977L93.2436 11.0075H91.9182C89.7985 11.0075 88.6987 12.7042 88.6987 14.3351V21.6718H84.9434V7.75977H88.5013L88.5812 9.49407C89.3285 8.30497 90.6351 7.75977 92.2425 7.75977H93.5162Z"
          fill="#002856"
        />
        <path
          d="M67.4872 21.6711H63.7272V14.3156C63.7272 12.774 63.5956 10.8376 61.4289 10.8376C59.2622 10.8376 58.5102 12.3416 58.5102 14.0947V21.6711H54.7549V7.75912H58.2376L58.3175 9.53572C59.0648 8.34662 60.456 7.41602 62.5052 7.41602C66.2934 7.41602 67.4872 10.1185 67.4872 12.9338V21.6711Z"
          fill="#002856"
        />
        <path
          d="M53.1145 18.4181L52.8513 21.5859C51.9489 21.8256 51.1499 21.9149 50.1817 21.9149C46.6426 21.9149 45.6133 20.2887 45.6133 17.0269V3.83398H49.3733V7.75848H53.1098L52.8513 11.0062H49.3733V16.7449C49.3733 18.0562 49.7305 18.7612 51.0747 18.7612C51.6575 18.7612 52.3061 18.6907 53.1145 18.4181Z"
          fill="#002856"
        />
        <path
          d="M44.3817 7.75977L44.0903 11.0075H43.0704C40.9554 11.0075 39.9026 12.7042 39.9026 14.3351V21.6718H36.1426V7.75977H39.7005L39.7757 9.49407C40.5277 8.30497 41.8296 7.75977 43.4464 7.75977H44.3817Z"
          fill="#002856"
        />
        <path
          d="M19.1901 20.1295C16.6427 21.4925 14.0953 22.2774 10.7348 22.1834C4.4979 21.9907 0 18.1179 0 11.5802C0 5.04251 4.5402 0.91121 10.7348 0.81251C13.8744 0.76551 16.685 1.55981 18.8799 3.50091L16.2338 6.17521C14.8755 5.01901 12.878 4.33751 10.4857 4.44561C6.5894 4.62891 4.2394 7.45831 4.1877 11.3546C4.136 15.5752 6.7257 18.4798 10.7677 18.5879C12.6712 18.6349 14.0342 18.3811 15.2139 17.85V13.5354H11.0121L11.2941 9.99631H19.1854V20.1201L19.1901 20.1295Z"
          fill="#002856"
        />
        <path
          d="M83.2648 16.035H72.9624C73.2538 17.8116 74.6544 18.8832 76.3934 18.9302C78.0854 18.9772 79.0301 18.4649 80.2709 17.21L82.7243 19.0806C80.9806 21.2285 78.8891 22.0087 76.5109 22.0087C72.3232 22.0087 69.1836 19.0806 69.1836 14.7143C69.1836 10.348 72.3091 7.41992 76.3558 7.41992C80.8161 7.41992 83.2695 10.1224 83.2695 14.9211V16.035H83.2648ZM79.5001 13.1257C79.3826 11.3679 78.0666 10.2587 76.2524 10.3292C74.4288 10.3997 73.2115 11.5888 72.9624 13.1257H79.5001Z"
          fill="#002856"
        />
        <path
          d="M33.7789 12.5586V21.6672H30.4654L30.3855 20.2948C29.3703 21.5544 27.7206 22.0056 26.0192 22.0056C23.4624 22.0056 21.0419 20.746 20.9949 17.8038C20.9197 13.1837 26.1931 12.8594 29.5066 12.8594H30.1693V12.6761C30.1693 11.1486 29.1588 10.3261 27.2553 10.3261C25.8876 10.3261 24.5434 10.876 23.5423 11.8066L21.3756 9.60231C23.3449 8.00431 25.3941 7.41211 27.5749 7.41211C31.8848 7.41211 33.7836 9.40491 33.7836 12.5539L33.7789 12.5586ZM30.1646 15.5995H29.6429C27.8663 15.5995 24.7408 15.6935 24.7502 17.55C24.769 20.1726 30.1646 19.336 30.1646 16.1776V15.5995Z"
          fill="#002856"
        />
        <path
          d="M90.583 20.0495C90.583 19.1659 91.2504 18.4985 92.1058 18.4985C92.9612 18.4985 93.6239 19.1659 93.6239 20.0495C93.6239 20.9331 92.9565 21.6146 92.1058 21.6146C91.2551 21.6146 90.583 20.9472 90.583 20.0495ZM92.1058 21.9248C93.1257 21.9248 93.9952 21.1305 93.9952 20.0495C93.9952 18.9685 93.1257 18.1836 92.1058 18.1836C91.0859 18.1836 90.207 18.9732 90.207 20.0495C90.207 21.1258 91.0765 21.9248 92.1058 21.9248ZM91.711 20.1952H92.087L92.6557 21.1305H93.0223L92.4066 20.1811C92.7215 20.1435 92.9659 19.9743 92.9659 19.5842C92.9659 19.1612 92.7121 18.9685 92.2045 18.9685H91.382V21.1258H91.7063V20.1905L91.711 20.1952ZM91.711 19.9179V19.2505H92.1575C92.3878 19.2505 92.6275 19.3022 92.6275 19.5654C92.6275 19.8991 92.3784 19.9179 92.1058 19.9179H91.7157H91.711Z"
          fill="#002856"
        />
      </g>
      <defs>
        <clipPath id="clip0_6479_17783">
          <rect width="94" height="21.3756" fill="white" transform="translate(0 0.8125)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GartnerLogoTM;
