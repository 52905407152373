import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { xDatePickerClasses, xDatePickerStyles } from "./xDatePickers.styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { CalendarIconSingle, CloseIcon } from "gx-npm-icons";
import React from "react";
import { TooltipV2 } from "../tooltip-v2";
import { MiniButton } from "../miniButton";
import dayjs from "dayjs";
import { createTheme, ThemeProvider } from "@mui/material";
import { pickersLayoutClasses } from "@mui/x-date-pickers";

export type xDatePickerPropTypes = {
  disabled?: boolean;
  onChange: (value: unknown) => void;
  toolTipText?: string;
  showIcon?: boolean;
  showToolTip?: boolean;
  toolTipPlacement?: string;
  toolTipRootClassName?: string;
  datePickerFormat?: string;
  actionButtonVariant?: string;
  showClearButton?: boolean;
  clearButtonText?: string;
  value?: string | Date | null;
  rootClassName?: string;
  minDate?: string | Date | null;
  placeholder?: string;
  onClose?: () => void;
  onOpen?: () => void;
  popperRootClassName?: string;
};

type ActionBarProps = {
  onClear: () => void;
};

const XDatePicker = ({
  disabled = false,
  toolTipText = "Add Date",
  toolTipRootClassName = "",
  showIcon = false,
  showToolTip = true,
  toolTipPlacement = "top",
  datePickerFormat = "MMMM DD, YYYY",
  actionButtonVariant = "default",
  showClearButton = true,
  clearButtonText = "Clear date",
  value = "",
  rootClassName = "",
  minDate = "",
  placeholder = "Add Date",
  onChange,
  onClose,
  onOpen,
  popperRootClassName = "",
}: xDatePickerPropTypes) => {
  const createStyles = makeStyles(() => xDatePickerClasses);
  const classes = createStyles();

  const ActionBar = (actionBarProps: ActionBarProps): React.JSX.Element => {
    return (
      <div className={classNames(classes.xActionBar)}>
        {showClearButton && (
          <MiniButton
            disabled={!value}
            onClick={actionBarProps.onClear}
            rootClassName={classNames("mini-btn", value && "mini-btn-enabled")}
            variant={actionButtonVariant}
            ariaLabel={placeholder}
          >
            <CloseIcon />
            {clearButtonText}
          </MiniButton>
        )}
      </div>
    );
  };

  const slots = {
    actionBar: showClearButton ? ActionBar : undefined,
    openPickerIcon: CalendarIconSingle,
  };
  const xDatePickerTheme = createTheme(xDatePickerStyles);

  const content = (
    <div className={classNames(rootClassName)}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={xDatePickerTheme}>
          <DatePicker
            className={classNames(classes.xDatePicker, showIcon && "show-icon")}
            disabled={disabled}
            format={datePickerFormat}
            minDate={dayjs(minDate)}
            slotProps={{
              popper: {
                className: popperRootClassName,
              },
              textField: {
                placeholder: placeholder,
              },
              layout: {
                sx: {
                  [`.${pickersLayoutClasses.contentWrapper}`]: {
                    "& .MuiIconButton-edgeEnd": {
                      position: "absolute",
                      left: 12,
                      top: 12,
                      padding: "8px",
                      borderRadius: "100%",
                    },
                    "& .MuiIconButton-edgeStart": {
                      position: "absolute",
                      right: 12,
                      top: 12,
                      padding: "8px",
                      borderRadius: "100%",
                    },
                  },
                },
              },
              calendarHeader: {
                sx: {
                  "&": {
                    padding: 0,
                    textAlign: "center",
                    display: "block",
                  },
                  "& .MuiPickersCalendarHeader-labelContainer": {
                    margin: "2px auto 0",
                    display: "block",
                    "& .MuiPickersCalendarHeader-label": {
                      margin: 0,
                    },
                  },
                },
              },
            }}
            slots={slots as never}
            views={["day"]}
            onChange={onChange}
            onClose={() => {
              onClose?.();
              // this is done to prevent refocus on picker button
              setTimeout(() => {
                (document.activeElement as HTMLElement).blur();
              }, 500);
            }}
            onOpen={onOpen}
            value={value ? dayjs(value) : null}
            dayOfWeekFormatter={(_day: string, weekday: { format: (value: string) => string }) =>
              `${weekday.format("dd")}`
            }
          />
        </ThemeProvider>
      </LocalizationProvider>
    </div>
  );

  return (
    <TooltipV2
      enterNextDelay={500}
      title={toolTipText}
      placement={toolTipPlacement}
      deactivate={!showToolTip}
      rootClassName={toolTipRootClassName}
    >
      {content}
    </TooltipV2>
  );
};

export default XDatePicker;
