const InfoIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 7.5C12.4971 7.5 12.9 7.90294 12.9 8.4C12.9 8.89706 12.4971 9.3 12 9.3C11.5029 9.3 11.1 8.89706 11.1 8.4C11.1 7.90294 11.5029 7.5 12 7.5Z"
        fill="#0000A9"
      />
      <path
        d="M12 16.5C11.505 16.5 11.1 16.095 11.1 15.6V12C11.1 11.505 11.505 11.1 12 11.1C12.495 11.1 12.9 11.505 12.9 12V15.6C12.9 16.095 12.495 16.5 12 16.5Z"
        fill="#0000A9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3C7.032 3 3 7.032 3 12C3 16.968 7.032 21 12 21C16.968 21 21 16.968 21 12C21 7.032 16.968 3 12 3ZM4.8 12C4.8 8.02611 8.02611 4.8 12 4.8C15.9739 4.8 19.2 8.02611 19.2 12C19.2 15.9739 15.9739 19.2 12 19.2C8.02611 19.2 4.8 15.9739 4.8 12Z"
        fill="#0000A9"
      />
    </svg>
  );
};

export default InfoIcon;
