import { use } from "i18next";
import { initReactI18next } from "react-i18next";
import appTranslations from "./translations/template.json";
import { gxNpmUiTranslations } from "gx-npm-ui";

const i18n = use(initReactI18next).init({
  fallbackLng: "en",
  interpolation: { escapeValue: false },
  defaultNS: "app",
  returnEmptyString: false,
  resources: {
    en: {
      app: appTranslations,
      common: gxNpmUiTranslations,
    },
  },
  ns: ["app", "common"],
});

export default i18n;
