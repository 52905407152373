import { useContext, useEffect, useMemo } from "react";
import classNames from "classnames";
import { debounce } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ProposalReviewContext } from "../../../app.context";
import { Section } from "../../../app.types";
import styles from "./nav-bar.styles.module.scss";
import { TypographyComponent } from "gx-npm-ui";

const NavBar = () => {
  const { t } = useTranslation();
  const { selectedSection, setSelectedSection } = useContext(ProposalReviewContext);

  const menu: { target: Section; title: string }[] = useMemo(
    () => [
      { target: Section.INSTRUCTIONS, title: t("Instructions") },
      { target: Section.DATES, title: t("Target dates") },
      { target: Section.DISCUSSION, title: t("Discussion topics") },
      { target: Section.DOCUMENTS, title: t("Documents") },
      { target: Section.COMMENTS, title: t("Comments") },
    ],
    [t]
  );

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + Math.round(window.scrollY) < document.body.scrollHeight) {
        let currSelected: Section = Section.INSTRUCTIONS;
        for (const key in menu) {
          const menuItem = menu[key];
          const menuItemElem = document.getElementById(menuItem.target);
          const menuItemElemTop = menuItemElem?.getBoundingClientRect().top;
          if (menuItemElemTop && menuItemElemTop < 140) {
            currSelected = menuItem.target;
          }
        }
        setSelectedSection(currSelected);
      }
    };

    const debouncedHandleScroll = debounce(handleScroll, 20);
    const handleScrollListener = () => debouncedHandleScroll();

    window.addEventListener("scroll", handleScrollListener);
    return () => {
      window.removeEventListener("scroll", handleScrollListener);
    };
  }, [menu, setSelectedSection]);

  const handleNavClick = (target: Section) => {
    if (target) {
      setSelectedSection(target);
      const sectionElem = document.getElementById(target);
      const yOffset = target === Section.INSTRUCTIONS ? -134 : -64;
      const sectionElemY = sectionElem?.getBoundingClientRect().top;

      if (sectionElemY) {
        const y = sectionElemY + window.scrollY + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }
  };

  return (
    <section className={styles.root}>
      <div className={styles.navContainer}>
        {menu.map((item) => {
          const { target, title } = item;
          return (
            <button
              aria-label={`${title} ${selectedSection === target ? t("is selected") : t("is not selected")}`}
              className={classNames(styles.navItem, selectedSection === target && styles.isSelected)}
              onClick={() => {
                handleNavClick(target);
              }}
              key={`menu-item-${target}`}
            >
              <TypographyComponent
                styling={"p3"}
                boldness={"medium"}
                color={selectedSection === target ? "yam" : "coal"}
              >
                {title}
              </TypographyComponent>
            </button>
          );
        })}
      </div>
    </section>
  );
};

export default NavBar;
