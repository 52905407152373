import { createContext, ReactNode, useState } from "react";
import { UUID } from "gx-npm-lib";
import { Document, ProposalReviewDocs, ProposalReviewContextValue, Section, SelectedTopic } from "./app.types";

const ProposalReviewContext = createContext<ProposalReviewContextValue>({} as ProposalReviewContextValue);
const ProposalReviewContextProvider = (props: { children: ReactNode | Array<ReactNode> }) => {
  const [comments, setComments] = useState<string>("");
  const [contractSignDate, setContractSignDate] = useState<string>("");
  const [documents, setDocuments] = useState<Array<Document>>([]);
  const [documentValidationError, setDocumentValidationError] = useState<boolean>(false);
  const [documentValidationErrorMessage, setDocumentValidationErrorMessage] = useState<string>("");
  const [hasError, setHasError] = useState<boolean>(false);
  const [hasFormError, setHasFormError] = useState<boolean>(false);
  const [initId, setInitId] = useState<UUID>("");
  const [initProductId, setInitProductId] = useState<UUID>("");
  const [isEligiblePR, setIsEligiblePR] = useState<boolean>(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [otherCheckbox, setOtherCheckbox] = useState<boolean>(false);
  const [otherNotes, setOtherNotes] = useState<string>("");
  const [expertSpeakDate, setExpertSpeakDate] = useState<string>("");
  const [productImageLoc, setProductImageLoc] = useState<string>("");
  const [productName, setProductName] = useState<string>("");
  const [proposalReviewDocs, setProposalReviewDocs] = useState<Array<ProposalReviewDocs>>([]);
  const [pricingAnalysisCheckbox, setPricingAnalysisCheckbox] = useState<boolean>(false);
  const [proposalReviewTerms, setProposalReviewTerms] = useState<string>("");
  const [refNumId, setRefNumId] = useState<string>("");
  const [selectedSection, setSelectedSection] = useState<Section>(Section.INSTRUCTIONS);
  const [selectedTopics, setSelectedTopics] = useState<Array<SelectedTopic>>([]);
  const [termsAndConditionsClause, setTermsAndConditionsClause] = useState<string>("");
  const [termsAndConditionsReviewCheckbox, setTermsAndConditionsReviewCheckbox] = useState<boolean>(false);

  const contextValue = {
    comments,
    contractSignDate,
    documents,
    documentValidationError,
    documentValidationErrorMessage,
    hasError,
    hasFormError,
    initId,
    initProductId,
    isEligiblePR,
    isFormSubmitting,
    isSubmitted,
    otherCheckbox,
    otherNotes,
    expertSpeakDate,
    pricingAnalysisCheckbox,
    productImageLoc,
    productName,
    proposalReviewDocs,
    proposalReviewTerms,
    refNumId,
    selectedSection,
    selectedTopics,
    termsAndConditionsClause,
    termsAndConditionsReviewCheckbox,
    setComments,
    setContractSignDate,
    setDocuments,
    setDocumentValidationError,
    setDocumentValidationErrorMessage,
    setHasError,
    setHasFormError,
    setInitId,
    setInitProductId,
    setIsEligiblePR,
    setIsFormSubmitting,
    setIsSubmitted,
    setOtherCheckbox,
    setOtherNotes,
    setExpertSpeakDate,
    setPricingAnalysisCheckbox,
    setProductImageLoc,
    setProductName,
    setProposalReviewDocs,
    setProposalReviewTerms,
    setRefNumId,
    setSelectedSection,
    setSelectedTopics,
    setTermsAndConditionsClause,
    setTermsAndConditionsReviewCheckbox,
  };

  return <ProposalReviewContext.Provider value={contextValue}>{props.children}</ProposalReviewContext.Provider>;
};

export { ProposalReviewContext, ProposalReviewContextProvider };
