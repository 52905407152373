import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ButtonLoader, TooltipV2, TypographyComponent } from "gx-npm-ui";
import { postAsyncRequest } from "gx-npm-lib";
import styles from "./submit.styles.module.scss";
import NoteIcon from "../instructions/note.icon";
import { ProposalReviewContext } from "../../../../app.context";
import { Topic } from "../../../../app.types";

const Submit = () => {
  const { t } = useTranslation();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const {
    comments,
    contractSignDate,
    documents,
    expertSpeakDate,
    hasFormError,
    initId,
    initProductId,
    proposalReviewTerms,
    selectedTopics,
    setHasError,
    setIsFormSubmitting,
    setIsSubmitted,
    setRefNumId,
  } = useContext(ProposalReviewContext);

  useEffect(() => {
    setIsSubmitDisabled(true);

    if (hasFormError) {
      return;
    }

    if (!contractSignDate) {
      return;
    }

    if (!expertSpeakDate) {
      return;
    }

    if (selectedTopics.length === 0) {
      return;
    }

    let isSelectedTopicsRequirementsMet = true;

    selectedTopics.forEach((selectedTopic) => {
      const { topic, notes } = selectedTopic;

      if (topic === Topic.TERMS_AND_CONDITIONS && !proposalReviewTerms && !notes) {
        isSelectedTopicsRequirementsMet = false;
      }
      if (topic === Topic.OTHER && !notes) {
        isSelectedTopicsRequirementsMet = false;
      }
    });

    if (!isSelectedTopicsRequirementsMet) {
      return;
    }

    if (documents && documents.length === 0) {
      return;
    }

    setIsSubmitDisabled(false);
  }, [contractSignDate, expertSpeakDate, documents, proposalReviewTerms, selectedTopics, hasFormError]);

  const handleSubmitClick = () => {
    const payload = {
      contractSignDate,
      expertSpeakDate,
      selectedTopics,
      documents: documents.map((document) => document.id),
      comments,
    };

    (async () => {
      setIsLoading(true);
      setIsFormSubmitting(true);

      const url = `/api/v2/initiatives/${initId}/products/${initProductId}/proposal-review/gproxy/submit`;
      const postResponse = await postAsyncRequest(url, payload);

      if (postResponse.status !== 201 || !postResponse.data?.data) {
        setIsFormSubmitting(false);
        setIsLoading(false);
        setHasError(true);
        return;
      }
      setIsLoading(false);
      setIsFormSubmitting(false);
      setRefNumId(postResponse.data.data.refNumId);
      setIsSubmitted(true);
    })();
  };

  return (
    <section className={styles.root}>
      <div className={styles.note}>
        <NoteIcon />
        <TypographyComponent boldness="medium" color="carbon" rootClassName={styles.noteDesc} styling="p2">
          {t("Note—Your Gartner associate will automatically be included in the proposal review process.")}
        </TypographyComponent>
      </div>
      <div className={styles.separator} />
      <div className={styles.buttonWrapper}>
        <span className={styles.required}>
          <TypographyComponent color="statusCherry" element="span" rootClassName={styles.asterisk} styling="p1">
            {"*"}
          </TypographyComponent>
          <TypographyComponent color="poisonCherry" element="span" rootClassName={styles.requiredText} styling="p3">
            {t("Indicates required")}
          </TypographyComponent>
        </span>
        <TooltipV2
          deactivate={!isSubmitDisabled}
          placement="top"
          PopperProps={{ modifiers: { offset: { offset: "0, 8" } } }}
          title={t("Please complete all the required fields to submit your request.")}
        >
          <div className={styles.buttonSubmit}>
            <ButtonLoader
              btnClass="btn-primary"
              disabled={isSubmitDisabled}
              isLoading={isLoading}
              onClick={handleSubmitClick}
            >
              {t("Submit request")}
            </ButtonLoader>
          </div>
        </TooltipV2>
      </div>
    </section>
  );
};

export default Submit;
